import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AbstractUserProfileService } from '@shared/components/user-info-update/services/abstract-user-profile-service';
import {
  FfNgxUserNamesInput,
} from '@fagforbundet/ngx-components/lib/ngx-user-profile-forms/types/input/ff-ngx-user-names-input';
import { PutSelfResponse } from '@core/models/responses/id-api/put-self/put-self-response';
import idApiConfig from '@config/apis/id/id-api.config';
import { User } from '@core/entities/user/user.entity';

@Injectable({
  providedIn: 'root',
})
export class UserProfileUserService extends AbstractUserProfileService {
  putUserName = (user: FfNgxUserNamesInput & { bankAccount?: string }): Observable<User> => {
    return this._requestService
      .put<PutSelfResponse>({
        api: idApiConfig,
        endpoint: idApiConfig.endpoints.PUT_SELF,
        requestBody: {
          user: {
            ...user,
            bankAccount: this._user?.bankAccount || null,
          },
        },
      })
      .pipe(
        map((response) => {
          return response.responseBody.user;
        }),
      );
  };

  putBankAccountNumber = (user: FfNgxUserNamesInput & { bankAccount: string }): Observable<User> => {
    return this._requestService.put<PutSelfResponse>({
      api: idApiConfig,
      endpoint: idApiConfig.endpoints.PUT_SELF,
      requestBody: {
        user: user
      }
    })
      .pipe(
        map((response) => {
          return response.responseBody.user;
        })
      )
  }
}
