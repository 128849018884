import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FfNgxButtonComponent,
  FfNgxCardComponent,
  FfNgxControlErrorDirective,
  FfNgxControlErrorMessages,
  FfNgxIconComponent,
  FfNgxLoadingIndicatorComponent,
} from '@fagforbundet/ngx-components';
import { AsyncPipe } from '@angular/common';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { catchError, finalize, take } from 'rxjs/operators';
import { BankAccountNumberValidators } from '@shared/validators/bank-account.validator';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'ff-ngx-bank-account-number-form',
  templateUrl: './ff-ngx-bank-account-number-form.component.html',
  styleUrls: ['./ff-ngx-bank-account-number-form.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    FfNgxButtonComponent,
    FfNgxCardComponent,
    FfNgxControlErrorDirective,
    FfNgxIconComponent,
    FfNgxLoadingIndicatorComponent,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
],
})
export class FfNgxBankAccountNumberFormComponent implements OnInit {
  #fb = inject(FormBuilder);
  disableCancel: boolean = false;

  @Input({ required: true })
  bankAccountNumber$: Observable<string>;

  @Input({ required: true })
  putBankAccountNumber: (value: string) => Observable<string>;

  form = this.#fb.group({
    bankAccountNumber: this.#fb.control(null, Validators.compose([BankAccountNumberValidators.required, BankAccountNumberValidators.valid])),
  });
  @Output()
  statusChanges = this.form.statusChanges;

  @ViewChild('input')
  inputRef: ElementRef<HTMLInputElement>;

  processing: boolean = false;
  bankAccountNumberSubject = new BehaviorSubject<string | null>(null);
  warningSubject = new BehaviorSubject<string[] | null>(null);
  warnings$ = this.warningSubject.asObservable();
  #cdr = inject(ChangeDetectorRef);
  #destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.bankAccountNumber$.pipe(
      takeUntilDestroyed(this.#destroyRef),
    ).subscribe((value: string) => {
      this.bankAccountNumberSubject.next(value);
    });

    this.bankAccountNumberSubject.asObservable()
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe((bankAccountNumber) => {
        this.disableCancel = !bankAccountNumber;

        this.form.setControl(
          'bankAccountNumber',
          this.#fb.control(bankAccountNumber, Validators.compose([BankAccountNumberValidators.required, BankAccountNumberValidators.valid])),
        );

        if (this.disableCancel) {
          this.enableForm();
          return;
        }

        this.disableForm();
        this.#cdr.detectChanges();
      });
  }

  clearWarnings(): void {
    this.warningSubject.next(null);
  }

  handleSubmit(): void {
    this.clearWarnings();
    if (this.form.valid) {
      this.form.disable();
      this.processing = true;
      this.putBankAccountNumber((this.form.controls.bankAccountNumber.value as string).replace(/\./g, '')).pipe(
        take(1),
        catchError((error) => {
          this.displayWarning(
            'Det skjedde en uventet feil under oppdatering av bankkontonummer',
          );
          return throwError(() => error);
        }),
        finalize(() => {
          this.processing = false;
          this.#cdr.detectChanges();
        }),
      ).subscribe((bankAccountNumber) => {
        this.bankAccountNumberSubject.next(bankAccountNumber);
      });
    } else {
      Object.entries(this.form.controls.bankAccountNumber.errors).forEach(([k, v]) => {
        if (v) {
          this.displayWarning(BANK_ACCOUNT_NUMBER_FORM_ERROR_MESSAGES[k]());
        }
      });
    }
  }

  displayWarning(message: string) {
    if (this.warningSubject.value) {
      this.warningSubject.next([...this.warningSubject.value, message]);
    } else {
      this.warningSubject.next([message]);
    }
  }

  disableForm(): void {
    this.form.disable({ emitEvent: false });
  }

  enableForm(): void {
    this.form.enable();
    this.#cdr.detectChanges();
    this.inputRef.nativeElement.focus();
  }
}

export const BANK_ACCOUNT_NUMBER_FORM_ERROR_MESSAGES = {
  bankAccountNumberRequired: () => 'Bankkontonummer må fylles ut',
  invalidBankAccount: () => 'Dette ser ikke ut som et gyldig bankkontonummer',
} satisfies FfNgxControlErrorMessages;
