<ff-ngx-card>
  <h2>Bankkontonummer</h2>
  @if (form.disabled) {
    <div class='bank-account-number-container'>
      <ff-ngx-icon name='24x24-thin/payment-card' [size]='24' />
      <span>{{ bankAccountNumberSubject | async | bankAccount }}</span>
    </div>
    <div class='buttons buttons--inline'>
      <button ff-ngx-button (click)='enableForm()' size='small' color='sand'>
        Rediger
      </button>
      @if (processing) {
        <ff-ngx-loading-indicator
          size='small'
          stroke='#3c3c3b'
          />
      }
    </div>
  }

  @if (form.enabled) {
    <form (ngSubmit)='handleSubmit()' [formGroup]='form'>
      <input
        #input
        id='bank-account'
        class='ff-input'
        placeholder='11 siffer'
        type='text'
        inputmode='numeric'
        [formControl]='form.controls.bankAccountNumber'
        />

      <div class='buttons buttons--inline'>
        <button ff-ngx-button size='medium' type='submit'>Lagre</button>
        <button
          ff-ngx-button
          size='medium'
          color='secondary'
          [disabled]='disableCancel'
          (click)='disableForm()'
          >
          Avbryt
        </button>
      </div>
    </form>
  }


</ff-ngx-card>
