import { inject } from '@angular/core';
import { User } from '@core/entities/user/user.entity';
import { FfNgxRequestService } from '@fagforbundet/ngx-components';

export abstract class AbstractUserProfileService {
  _user: User;
  _requestService = inject(FfNgxRequestService);

  setUser(user: User): void {
    this._user = user;
  }
}
