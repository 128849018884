import { AsyncValidator, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { map, Observable, switchMap, timer } from 'rxjs';
import { Injectable } from '@angular/core';
import { FfNgxEmailAddressError, FfNgxRequestService } from '@fagforbundet/ngx-components';
import idApiConfig from '@config/apis/id/id-api.config';
import {
  GetValidateEmailAddressResponse,
} from '@core/models/responses/id-api/get-validate-email-address/get-validate-email-address-response';

@Injectable({
  providedIn: 'root',
})
export class EmailAddressValidator implements AsyncValidator {
  constructor(private readonly _requestService: FfNgxRequestService) {}

  validate: AsyncValidatorFn = ({
    value,
  }): Observable<ValidationErrors | null> => {
    return timer(100).pipe(
      switchMap(() => {
        return this._requestService
          .get<GetValidateEmailAddressResponse>({
            api: idApiConfig,
            endpoint: idApiConfig.endpoints.GET_VALIDATE_EMAIL_ADDRESS,
            endpointVars: {
              emailAddress: value,
            },
          })
          .pipe(
            map((response) => {
              if (response.responseBody.valid) {
                return null;
              }

              let r = {
                invalid_email_address_error: {},
              } as FfNgxEmailAddressError;

              return r;
            }),
          );
      }),
    );
  };
}
