import { Injectable } from '@angular/core';
import { AbstractUserProfileService } from '@shared/components/user-info-update/services/abstract-user-profile-service';
import { Observable } from 'rxjs';
import { FfNgxPostalAddress, FfNgxPostalAreaLookupResponse } from '@fagforbundet/ngx-components';
import { map } from 'rxjs/operators';
import idApiConfig from '@config/apis/id/id-api.config';
import {
  FfNgxPostalAddressInput,
} from '@fagforbundet/ngx-components/lib/ngx-user-profile-forms/types/input/ff-ngx-postal-address-input';
import {
  GetPostalCodeLookupResponse,
} from '@core/models/responses/id-api/get-postal-code-lookup/get-postal-code-lookup-response';
import {
  PostPostalAddressResponse,
} from '@core/models/responses/id-api/post-postal-address/post-postal-address-response';
import { PutPostalAddressResponse } from '@core/models/responses/id-api/put-postal-address/put-postal-address-response';
import { RequestServiceInMemoryStoreNames } from '@core/enums/request-service-in-memory-store-names';

@Injectable({
  providedIn: 'root'
})
export class UserProfilePostalAddressService extends AbstractUserProfileService {
  postalCodeLookup = (
    postalCode: string,
  ): Observable<FfNgxPostalAreaLookupResponse> => {
    return this._requestService
      .get<GetPostalCodeLookupResponse>(
        {
          api: idApiConfig,
          endpoint: idApiConfig.endpoints.GET_POSTAL_CODE_LOOKUP,
          endpointVars: {
            postalCode,
          },
        },
        { store: RequestServiceInMemoryStoreNames.STORE_POSTAL_CODES },
      )
      .pipe(
        map((response) => {
          return {
            ...response.responseBody.postalCode,
          };
        }),
      );
  };

  postAddress = (
    postalAddress: FfNgxPostalAddressInput,
  ): Observable<FfNgxPostalAddress> => {
    if (!this._user) {
      throw new Error('Unable to POST address. Missing user.');
    }

    return this._requestService
      .post<PostPostalAddressResponse>({
        api: idApiConfig,
        endpoint: idApiConfig.endpoints.POST_POSTAL_ADDRESS,
        requestBody: {
          address: { ...postalAddress, city: postalAddress.postalArea },
          userUuid: this._user.uuid,
        },
      })
      .pipe(
        map((response) => {
          return {
            ...response.responseBody.address,
            postalArea: response.responseBody.address.city,
          };
        }),
      );
  };

  putAddress = (
    postalAddress: FfNgxPostalAddressInput & { uuid: string },
  ): Observable<FfNgxPostalAddress> => {
    return this._requestService
      .put<PutPostalAddressResponse>({
        api: idApiConfig,
        endpoint: idApiConfig.endpoints.PUT_POSTAL_ADDRESS,
        endpointVars: { uuid: postalAddress.uuid },
        requestBody: {
          address: { ...postalAddress, city: postalAddress.postalArea },
        },
      })
      .pipe(
        map((response) => {
          return {
            ...response.responseBody.address,
            postalArea: response.responseBody.address.city,
          };
        }),
      );
  };
}
