import { Injectable } from '@angular/core';
import { FfNgxPhoneNumber } from '@fagforbundet/ngx-components';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  PostSetPrimaryPhoneNumberResponse,
} from '@core/models/responses/id-api/post-set-primary-phone-number/post-set-primary-phone-number-response';
import {
  PostSendPhoneNumberVerificationSmsResponse,
} from '@core/models/responses/id-api/post-send-phone-number-verification-sms/post-send-phone-number-verification-sms-response';
import {
  PostVerifyPhoneNumberResponse,
} from '@core/models/responses/id-api/post-verify-phone-number/post-verify-phone-number-response';
import { PostPhoneNumberResponse } from '@core/models/responses/id-api/post-phone-number/post-phone-number-response';
import {
  DeletePhoneNumberResponse,
} from '@core/models/responses/id-api/delete-phone-number/delete-phone-number-response';
import { AbstractUserProfileService } from '@shared/components/user-info-update/services/abstract-user-profile-service';
import idApiConfig from '@config/apis/id/id-api.config';
import {
  FfNgxPhoneNumberInput,
} from '@fagforbundet/ngx-components/lib/ngx-user-profile-forms/types/input/ff-ngx-phone-number-input';

@Injectable({
  providedIn: 'root'
})
export class UserProfilePhoneNumberService extends AbstractUserProfileService {
  deletePhoneNumber = (
    phoneNumber: FfNgxPhoneNumber,
  ): Observable<FfNgxPhoneNumber> => {
    return this._requestService
      .delete<DeletePhoneNumberResponse>({
        api: idApiConfig,
        endpoint: idApiConfig.endpoints.DELETE_PHONE_NUMBER,
        endpointVars: {
          uuid: phoneNumber.uuid,
        },
      })
      .pipe(
        map((r) => {
          return {
            ...r.responseBody.phone,
            phoneNumber: `+${r.responseBody.phone.countryCode}${r.responseBody.phone.number}`,
          };
        }),
      );
  };

  postPhoneNumber = (
    phoneNumber: FfNgxPhoneNumberInput,
  ): Observable<FfNgxPhoneNumber> => {
    return this._requestService
      .post<PostPhoneNumberResponse>({
        api: idApiConfig,
        endpoint: idApiConfig.endpoints.POST_PHONE_NUMBER,
        requestBody: {
          phone: {
            number: `+${phoneNumber.countryCode}${phoneNumber.number}`,
          },
          userUuid: this._user.uuid,
        },
      })
      .pipe(
        map((r) => {
          return {
            ...r.responseBody.phone,
            phoneNumber: `+${r.responseBody.phone.countryCode}${r.responseBody.phone.number}`,
          };
        }),
      );
  };

  sendPhoneNumberVerificationSms = (
    phoneNumber: FfNgxPhoneNumber,
  ): Observable<FfNgxPhoneNumber> => {
    return this._requestService
      .post<PostSendPhoneNumberVerificationSmsResponse>({
        api: idApiConfig,
        endpoint: idApiConfig.endpoints.POST_SEND_PHONE_NUMBER_VERIFICATION_SMS,
        endpointVars: {
          uuid: phoneNumber.uuid,
        },
      })
      .pipe(
        map((r) => {
          return {
            ...r.responseBody.phone,
            phoneNumber: `+${r.responseBody.phone.countryCode}${r.responseBody.phone.number}`,
          };
        }),
      );
  };

  setPrimaryPhoneNumber = (
    phoneNumber: FfNgxPhoneNumber,
  ): Observable<FfNgxPhoneNumber> => {
    return this._requestService
      .post<PostSetPrimaryPhoneNumberResponse>({
        api: idApiConfig,
        endpoint: idApiConfig.endpoints.POST_SET_PRIMARY_PHONE_NUMBER,
        endpointVars: {
          uuid: phoneNumber.uuid,
        },
      })
      .pipe(
        map((r) => {
          return {
            ...r.responseBody.phone,
            phoneNumber: `+${r.responseBody.phone.countryCode}${r.responseBody.phone.number}`,
          };
        }),
      );
  };

  verifyPhoneNumber = (
    phoneNumber: FfNgxPhoneNumber,
    code: string,
  ): Observable<FfNgxPhoneNumber> => {
    return this._requestService
      .post<PostVerifyPhoneNumberResponse>({
        api: idApiConfig,
        endpoint: idApiConfig.endpoints.GET_VERIFY_PHONE_NUMBER,
        endpointVars: {
          uuid: phoneNumber.uuid,
          code,
        },
      })
      .pipe(
        map((r) => {
          return {
            ...r.responseBody.phone,
            phoneNumber: `+${r.responseBody.phone.countryCode}${r.responseBody.phone.number}`,
          };
        }),
      );
  };
}
