import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  DestroyRef,
  inject,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Data, Params } from '@angular/router';
import { ClientEntity } from '@core/entities/client/client.entity';
import { PendingInformationUpdates } from '@core/entities/login/pending-information-updates.entity';
import {
  OauthAuthorizeRedirectQueryParamsEntity,
} from '@core/entities/query-params/oauth-authorize-redirect-query-params.entity';
import { SsoDataQueryParamsEntity } from '@core/entities/query-params/sso-data-query-params.entity';
import { SsoDataResponse } from '@core/entities/response/sso-data-response.entity';
import { User } from '@core/entities/user/user.entity';
import { NavigationHelper } from '@core/helpers/navigation.helper';
import { AuthenticationService } from '@core/services/authentication/authentication.service';
import { UserService } from '@core/services/user/user.service';
import { ErrorPageErrorsEnum, FfErrorPageComponent } from '@shared/components/ff-error-page/ff-error-page.component';
import { LoginFormsComponent } from '@shared/components/login-forms/login-forms.component';
import { SetUserPasswordComponent } from '@shared/components/set-user-password/set-user-password.component';
import { UserInfoUpdateComponent } from '@shared/components/user-info-update/user-info-update.component';
import { combineLatest, of, throwError } from 'rxjs';
import { catchError, concatMap, take, tap } from 'rxjs/operators';
import { deserialize } from 'serializr';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsoComponent implements AfterViewInit {
  @ViewChild('viewContainerRef', { read: ViewContainerRef })
  viewContainerRef: ViewContainerRef;
  clientInfo: ClientEntity;
  #authenticationService = inject(AuthenticationService);
  #activatedRoute = inject(ActivatedRoute);
  #cdr = inject(ChangeDetectorRef);
  #destroyRef = inject(DestroyRef);
  #userService = inject(UserService);
  #oauthAuthorizeRedirect: OauthAuthorizeRedirectQueryParamsEntity;
  #ssoDataParams: SsoDataQueryParamsEntity;

  ngAfterViewInit() {
    combineLatest([this.#activatedRoute.data, this.#activatedRoute.queryParams])
      .pipe(
        tap(([data, params]: [Data, Params]) => {
          this.clientInfo = data['clientInfo'];
          this.#ssoDataParams = deserialize(SsoDataQueryParamsEntity, this.#activatedRoute.snapshot.queryParams);
          this.#oauthAuthorizeRedirect = deserialize(OauthAuthorizeRedirectQueryParamsEntity, params);

          this._handleAuthenticationResponse(data['ssoData']);
        }),
        catchError((e) => {
          this._showError();
          return throwError(() => e);
        }),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe();
  }

  private _checkPendingInfoUpdates(): void {
    this.#userService
      .getPendingInformationUpdates()
      .pipe(
        tap((pending: PendingInformationUpdates) => {
          if (pending.verificationRequired || pending.hasPendingUpdates()) {
            if (pending.password.required) {
              this._showUserPasswordForm(pending);
            } else {
              this._showUserInfoForm(pending);
            }
          } else {
            this._retryAuthenticationProcess();
          }
        }),
        take(1),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe();
  }

  private _handleAuthenticationResponse(ssoData: SsoDataResponse): void {
    if (this.#oauthAuthorizeRedirect.isOidcCallback) {
      this._loginCallback();
    } else {
      switch (ssoData.status) {
        case 200:
          this._postAuthRedir(ssoData);
          break;
        case 400:
          this._showError();
          break;
        case 401:
          this._showLoginForm();
          break;
        case 404:
          this._showError(ErrorPageErrorsEnum.PAGE_NOT_FOUND);
          break;
        case 428:
          this._checkPendingInfoUpdates();
          break;
        default:
          this._showError();
          break;
      }
    }
  }

  #loadComponent<T>(form: any): ComponentRef<T> {
    this.viewContainerRef.clear();
    return this.viewContainerRef.createComponent<T>(form);
  }

  private _loginCallback(): void {
    this._checkPendingInfoUpdates();
  }

  private _retryAuthenticationProcess(): void {
    this.#authenticationService
      .ssoAuthenticate(this.#ssoDataParams.appId, this.#ssoDataParams.returnTo)
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe({
        next: (ssoData: SsoDataResponse) => {
          if (this.#oauthAuthorizeRedirect.isOidcCallback) {
            this._postAuthRedir(ssoData);
          } else {
            this._handleAuthenticationResponse(ssoData);
          }
        },
        error: (error) => {
          this._handleAuthenticationResponse(error);
        },
      });
  }

  private _showError(error: ErrorPageErrorsEnum = ErrorPageErrorsEnum.LOGIN) {
    const component = this.#loadComponent<FfErrorPageComponent>(FfErrorPageComponent);
    component.instance.errorType = error;
    this.#cdr.detectChanges();
  }

  private _showLoginForm(): void {
    const component = this.#loadComponent<LoginFormsComponent>(LoginFormsComponent);

    component.instance.clientInfo = this.clientInfo;

    component.instance.loggedInCallback
      .pipe(
        tap(() => {
          this._loginCallback();
        }),
        takeUntilDestroyed(this.#destroyRef),
        catchError((e) => {
          this._showError();
          return throwError(() => e);
        }),
      )
      .subscribe();
    this.#cdr.detectChanges();
  }

  private _showUserInfoForm(pending: PendingInformationUpdates): void {
    this.#userService
      .getSelf()
      .pipe(
        tap((user: User) => {
          const component = this.#loadComponent<UserInfoUpdateComponent>(UserInfoUpdateComponent);
          component.instance.user = user;
          component.instance.pendingInformationUpdates = pending;

          component.instance.proceedCallback
            .pipe(
              concatMap(() => {
                if (pending.verificationRequired) {
                  return this.#authenticationService.userInfoUpdated().pipe(
                    tap((infoVerified: boolean) => {
                      if (infoVerified) {
                        this._retryAuthenticationProcess();
                      } else {
                        this._showError();
                      }
                    }),
                  );
                } else {
                  this._retryAuthenticationProcess();
                  return of(null);
                }
              }),
              take(1),
              takeUntilDestroyed(this.#destroyRef),
              catchError((e) => {
                this._showError();
                return throwError(() => e);
              }),
            )
            .subscribe();
          this.#cdr.detectChanges();
        }),
        catchError((e) => {
          this._showError();
          return throwError(() => e);
        }),
        take(1),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe();
  }

  private _showUserPasswordForm(pending: PendingInformationUpdates): void {
    this.#userService
      .getSelf()
      .pipe(
        tap((user: User) => {
          const component = this.#loadComponent<SetUserPasswordComponent>(SetUserPasswordComponent);
          component.instance.user = user;
          component.instance.pendingInfoUpdates = pending;
          component.instance.proceedCallback.subscribe(() => {
            this._showUserInfoForm(pending);
          });
          this.#cdr.detectChanges();
        }),
        takeUntilDestroyed(this.#destroyRef),
        take(1),
      )
      .subscribe();
  }

  private _postAuthRedir(ssoData: SsoDataResponse): void {
    NavigationHelper.redirect(ssoData.redirectTo + '?jwt=' + ssoData.jwt);
  }
}
