import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PendingInformationUpdates } from '@core/entities/login/pending-information-updates.entity';
import { User } from '@core/entities/user/user.entity';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { NavigationHelper } from '@core/helpers/navigation.helper';
import { ProcessableInterface } from '@core/interfaces/processable.interface';
import { AuthenticationService } from '@core/services/authentication/authentication.service';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { finalize, take, tap } from 'rxjs/operators';
import { SharedModule } from '@shared/shared.module';
import { UserOverviewComponent } from '@shared/components/user-overview/user-overview.component';
import {
  FfNgxButtonComponent,
  FfNgxCardComponent,
  FfNgxCountry,
  FfNgxEmailAddress,
  FfNgxEmailAddressCardComponent,
  FfNgxIconComponent,
  FfNgxNameAndAddressCardComponent,
  FfNgxPhoneNumber,
  FfNgxPhoneNumberCardComponent,
  FfNgxPostalAddress,
  FfNgxRequestService,
  FfNgxUserNames,
} from '@fagforbundet/ngx-components';
import { UserProfileUserService } from '@shared/components/user-info-update/services/user-profile-user-service';
import {
  UserProfilePostalAddressService,
} from '@shared/components/user-info-update/services/user-profile-postal-address-service';
import {
  UserProfilePhoneNumberService,
} from '@shared/components/user-info-update/services/user-profile-phone-number-service';
import {
  UserProfileEmailAddressService,
} from '@shared/components/user-info-update/services/user-profile-email-address-service';
import { EmailAddressValidator } from '@core/validators/async/email-address.validator';
import { PhoneNumberValidator } from '@core/validators/async/phone-number.validator';
import { PhoneCountryCode } from '@core/models/phone-country-code/phone-country-code';
import { GetCountriesResponse } from '@core/models/responses/id-api/get-countries/get-countries-response';
import { getCountriesRequest } from '@core/models/responses/id-api/get-countries/get-countries-request';
import { RequestServiceInMemoryStoreNames } from '@core/enums/request-service-in-memory-store-names';
import { GetPhoneCodesResponse } from '@core/models/responses/id-api/get-phone-codes/get-phone-codes-response';
import { getPhoneCodesRequest } from '@core/models/responses/id-api/get-phone-codes/get-phone-codes-request';
import {
  FfNgxBankAccountNumberFormComponent,
} from '@shared/components/ff-ngx-bank-account-number-form/ff-ngx-bank-account-number-form.component';
import { PendingInformationUpdate } from '@core/entities/login/pending-information-update.entity';
import { FormControlStatus } from '@angular/forms';
import {
  FfNgxUserNamesInput,
} from '@fagforbundet/ngx-components/lib/ngx-user-profile-forms/types/input/ff-ngx-user-names-input';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-user-info-update',
  templateUrl: './user-info-update.component.html',
  styleUrls: ['./user-info-update.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    UserOverviewComponent,
    FfNgxNameAndAddressCardComponent,
    FfNgxPhoneNumberCardComponent,
    FfNgxEmailAddressCardComponent,
    FfNgxButtonComponent,
    FfNgxBankAccountNumberFormComponent,
    FfNgxCardComponent,
    FfNgxIconComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInfoUpdateComponent implements OnInit, ProcessableInterface {
  @Input({ required: true })
  pendingInformationUpdates: PendingInformationUpdates;
  @Input({ required: true })
  user: User;

  @Output()
  proceedCallback: EventEmitter<boolean> = new EventEmitter();
  processing: boolean = false;
  warningSubject = new BehaviorSubject<string[] | null>(null);
  warnings$ = this.warningSubject.asObservable();
  protected employmentSection?: PendingInformationUpdate;
  protected readonly names$ = new BehaviorSubject<FfNgxUserNames | null>(null);
  protected readonly addresses$ = new BehaviorSubject<FfNgxPostalAddress[]>([]);
  protected readonly countries$ = new BehaviorSubject<FfNgxCountry[]>([]);
  protected readonly countryCodes$ = new BehaviorSubject<PhoneCountryCode[]>([]);
  protected readonly bankAccountNumber$ = new BehaviorSubject<string | null>(null);
  protected readonly phoneNumbers$ = new BehaviorSubject<FfNgxPhoneNumber[]>([]);
  protected readonly emailAddresses$ = new BehaviorSubject<FfNgxEmailAddress[]>([]);
  protected readonly emailAddressService = inject(
    UserProfileEmailAddressService,
  );
  protected readonly phoneNumberService = inject(UserProfilePhoneNumberService);
  protected readonly postalAddressService = inject(
    UserProfilePostalAddressService,
  );
  protected readonly userService = inject(UserProfileUserService);
  protected readonly emailAddressValidator = inject(EmailAddressValidator);
  protected readonly phoneNumberValidator = inject(PhoneNumberValidator);
  #validState: {
    namesAndAddresses: FormControlStatus,
    bankAccountNumber: FormControlStatus,
    phoneNumbers: FormControlStatus,
    emailAddresses: FormControlStatus,
  } = {
    namesAndAddresses: undefined,
    bankAccountNumber: undefined,
    phoneNumbers: undefined,
    emailAddresses: undefined,
  };
  protected readonly validState$ = new BehaviorSubject(this.#validState);
  #activatedRoute = inject(ActivatedRoute);
  #authService = inject(AuthenticationService);
  #requestService = inject(FfNgxRequestService);

  #debugBankAccountNumber = environment.frontEnd.userInfoUpdateForm.debugBankAccountNumber;

  ngOnInit() {
    this.fetchCountries();
    this.fetchCountryCodes();

    this.#setUser(this.user);
    this.#refreshValidState();

    this.names$.next({
      firstName: this.userService._user.firstName,
      lastName: this.userService._user.lastName,
    });
    this.addresses$.next(this.userService._user.postalAddresses);
    this.bankAccountNumber$.next(this.userService._user.bankAccount);
    this.phoneNumbers$.next(this.userService._user.phones);
    this.emailAddresses$.next(this.userService._user.emails);

    if (this.#debugBankAccountNumber) {
      this.employmentSection = {
        _editAllowed: true,
        _required: true,
        get required(): boolean {
          return true;
        },
        get editAllowed(): boolean {
          return true;
        },
      } as unknown as PendingInformationUpdate;
    } else {
      this.employmentSection = this.pendingInformationUpdates.employmentInfo;
    }
  }

  logout(): void {
    if (this.processing) {
      return;
    }

    this.#authService
      .logout()
      .pipe(
        take(1),
        finalize(() => {
          NavigationHelper.redirect(
            this.#activatedRoute.snapshot.paramMap.get('redir') || EnvironmentHelper.getLogoutUrl(),
          );
        }),
      )
      .subscribe();
  }

  isValid(): boolean {
    let v = true;

    for (let s of Object.values(this.validState$.value)) {
      if (['INVALID', 'PENDING'].indexOf(s) !== -1) {
        v = false;
      }
    }

    return v;
  }

  confirm(): void {
    if (!this.isValid()) {
      this.warningSubject.next([
        'Det ser ut til å være noe som ikke helt stemmer i skjemaet.',
        'Ta gjerne en titt. Kanskje noe mangler.'
      ]);

      return;
    }
    this.processing = true;
    this.proceedCallback.next(true);
  }

  nameAndAddressStatusChanged(event: FormControlStatus) {
    this.#validState.namesAndAddresses = event;
    this.validState$.next(this.#validState);
  }

  bankAccountNumberStatusChanged(event: FormControlStatus) {
    this.#validState.bankAccountNumber = event;
    this.validState$.next(this.#validState);
  }

  phoneNumberStatusChanged(event: FormControlStatus) {
    this.#validState.phoneNumbers = event;
    this.validState$.next(this.#validState);
  }

  emailAddressStatusChanged(event: FormControlStatus) {
    this.#validState.emailAddresses = event;
    this.validState$.next(this.#validState);
  }

  putUserName = (user: FfNgxUserNamesInput): Observable<FfNgxUserNames> => {
    return this.userService.putUserName(user).pipe(
      tap((user) => {
        this.#setUser(user);
      }),
      map((user) => {
        return {
          firstName: user.firstName,
          lastName: user.lastName,
        };
      }),
    );
  };

  putBankAccountNumber = (bankAccountNumber: string): Observable<string> => {
    return this.userService.putBankAccountNumber({
      firstName: this.userService._user.firstName,
      lastName: this.userService._user.lastName,
      bankAccount: bankAccountNumber,
    }).pipe(
      tap((user) => {
        this.#setUser(user);
      }),
      map((user) => {
        return user.bankAccount;
      }),
    );
  };

  #refreshValidState(): void {
    this.#validState.phoneNumbers = this.user.phones.length === 0 ? 'INVALID' : 'VALID';
    this.#validState.emailAddresses = this.user.emails.length === 0 ? 'INVALID' : 'VALID';
    this.validState$.next(this.#validState);
  }

  #setUser(user: User): void {
    this.user = user;
    this.emailAddressService.setUser(user);
    this.phoneNumberService.setUser(user);
    this.postalAddressService.setUser(user);
    this.userService.setUser(user);
  }

  private fetchCountries() {
    this.#requestService.get<GetCountriesResponse>(getCountriesRequest('nb-NO'), {
      store: RequestServiceInMemoryStoreNames.STORE_COUNTRIES,
      cacheTtl: 604800000,
    })
      .pipe(
        map((response) => {
          return response.responseBody.countries;
        }),
      ).subscribe((countries) => {
      this.countries$.next(countries);
    });
  }

  private fetchCountryCodes() {
    this.#requestService.get<GetPhoneCodesResponse>(getPhoneCodesRequest('no'), {
      store: RequestServiceInMemoryStoreNames.STORE_PHONE_COUNTRY_CODES,
    })
      .pipe(
        map((response) => {
          return response.responseBody.phoneCountryCodes;
        }),
      ).subscribe((countryCodes) => {
      this.countryCodes$.next(countryCodes);
    });
  }
}
