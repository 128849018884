<div class='user-info-wrapper'>
  <h1 class='ff-h1'>
    Er dette riktig?
  </h1>
  <span class='subtitle'>
    Vi trenger å vite at opplysningene dine er riktige. Sjekk at dette stemmer før du går videre.
  </span>

  <ff-ngx-name-and-address-card
    [names$]='names$'
    [addresses$]='addresses$'
    [countries$]='countries$'
    [getPostalArea]='postalAddressService.postalCodeLookup'
    [postAddress]='postalAddressService.postAddress'
    [putAddress]='postalAddressService.putAddress'
    [putUserName]='putUserName'
    (statusChanges)='nameAndAddressStatusChanged($event)'
    />

  @if (employmentSection?.required) {
    <ff-ngx-bank-account-number-form
      (statusChanges)='bankAccountNumberStatusChanged($event)'
      [putBankAccountNumber]='putBankAccountNumber'
      [bankAccountNumber$]='bankAccountNumber$'
      />
  }

  <ff-ngx-phone-number-card
    [countryCodes$]='countryCodes$'
    [phonesNumbers$]='phoneNumbers$'
    [postPhoneNumberVerification]='phoneNumberService.verifyPhoneNumber'
    [deletePhoneNumber]='phoneNumberService.deletePhoneNumber'
    [postRequestPhoneNumberVerification]='phoneNumberService.sendPhoneNumberVerificationSms'
    [setPrimaryPhoneNumber]='phoneNumberService.setPrimaryPhoneNumber'
    [postNewPhoneNumber]='phoneNumberService.postPhoneNumber'
    [asyncPhoneNumberValidators]='[phoneNumberValidator.validate]'
    (statusChanges)='phoneNumberStatusChanged($event)'
    />

  <ff-ngx-email-address-card
    [emailAddresses$]='emailAddresses$'
    [postNewEmailAddress]='emailAddressService.postEmailAddress'
    [deleteEmailAddress]='emailAddressService.deleteEmailAddress'
    [postRequestEmailAddressVerification]='emailAddressService.sendEmailAddressVerificationEmail'
    [setPrimaryEmailAddress]='emailAddressService.setPrimaryEmailAddress'
    [asyncEmailAddressValidators]='[emailAddressValidator.validate]'
    (statusChanges)='emailAddressStatusChanged($event)'
    />

  @if (warnings$ | async; as warnings) {
    <ff-ngx-card >
      <div class='warnings'>
        <ff-ngx-icon name='24x24-thick/form-error' [size]='24' />
        <ul>
          @for (warning of warnings; track warning) {
            <li>
              {{ warning }}
            </li>
          }
        </ul>
      </div>
    </ff-ngx-card>
  }

  <button
    ff-ngx-button
    color='primary'
    (click)='confirm()'
    [processing]='processing'
    [disabled]='false'
    role='button'
    >
    Bekreft
  </button>

  <button ff-ngx-button color='secondary' (click)='logout()' tabindex='0' role='button'>Avbryt</button>
</div>
