import { Injectable } from '@angular/core';
import { AbstractUserProfileService } from '@shared/components/user-info-update/services/abstract-user-profile-service';
import { FfNgxEmailAddress } from '@fagforbundet/ngx-components';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  DeleteEmailAddressResponse,
} from '@core/models/responses/id-api/delete-email-address/delete-email-address-response';
import { environment } from '@environments/environment';
import idApiConfig from '@config/apis/id/id-api.config';
import { PostEmailAddressResponse } from '@core/models/responses/id-api/post-email-address/post-email-address-response';
import {
  PostSendEmailAddressVerificationEmailResponse,
} from '@core/models/responses/id-api/post-send-email-address-verification-email/post-send-email-address-verification-email-response';
import {
  PostSetPrimaryEmailAddressResponse,
} from '@core/models/responses/id-api/post-set-primary-email-address/post-set-primary-email-address-response';

@Injectable({
  providedIn: 'root'
})
export class UserProfileEmailAddressService extends AbstractUserProfileService {
  deleteEmailAddress = (
    emailAddress: FfNgxEmailAddress,
  ): Observable<FfNgxEmailAddress> => {
    return this._requestService
      .delete<DeleteEmailAddressResponse>({
        api: idApiConfig,
        endpoint: idApiConfig.endpoints.DELETE_EMAIL_ADDRESS,
        endpointVars: {
          uuid: emailAddress.uuid,
        },
      })
      .pipe(
        map((r) => {
          return {
            ...r.responseBody.email,
            emailAddress: r.responseBody.email.email,
          };
        }),
      );
  };

  postEmailAddress = (emailAddress: string): Observable<FfNgxEmailAddress> => {
    return this._requestService
      .post<PostEmailAddressResponse>({
        api: idApiConfig,
        endpoint: idApiConfig.endpoints.POST_EMAIL_ADDRESS,
        requestBody: {
          email: {
            email: emailAddress,
          },
          frontEndUrl: environment.frontEnd.baseUrl,
          verifyUrl: environment.frontEnd.verifyEmailAddressUrl,
          userUuid: this._user.uuid,
        },
      })
      .pipe(
        map((r) => {
          return {
            ...r.responseBody.email,
            emailAddress: r.responseBody.email.email,
          };
        }),
      );
  };

  sendEmailAddressVerificationEmail = (
    emailAddress: FfNgxEmailAddress,
  ): Observable<FfNgxEmailAddress> => {
    return this._requestService
      .post<PostSendEmailAddressVerificationEmailResponse>({
        api: idApiConfig,
        endpoint: idApiConfig.endpoints.POST_SEND_EMAIL_ADDRESS_VERIFICATION_EMAIL,
        endpointVars: {
          uuid: emailAddress.uuid,
        },
        requestBody: {
          verifyUrl: environment.frontEnd.verifyEmailAddressUrl,
        },
      })
      .pipe(
        map((r) => {
          return {
            ...r.responseBody.email,
            emailAddress: r.responseBody.email.email,
          };
        }),
      );
  };

  setPrimaryEmailAddress = (
    emailAddress: FfNgxEmailAddress,
  ): Observable<FfNgxEmailAddress> => {
    return this._requestService
      .post<PostSetPrimaryEmailAddressResponse>({
        api: idApiConfig,
        endpoint: idApiConfig.endpoints.POST_SET_PRIMARY_EMAIL_ADDRESS,
        endpointVars: {
          uuid: emailAddress.uuid,
        },
      })
      .pipe(
        map((r) => {
          return {
            ...r.responseBody.email,
            emailAddress: r.responseBody.email.email,
          };
        }),
      );
  };
}
